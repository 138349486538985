$(document).ready(function () {
  ZoomImg()

  $(".product-list-desc .view-more").click(function (e) {
    e.preventDefault();
    $(".product-list-desc .content").toggleClass("open");
    $(this).toggleClass("open");
    $(this).find(".text").toggleText("Xem thêm", "Thu Gọn");
  });

  $(".product-detail-3 .view-more").click(function (e) {
    e.preventDefault();
    $(".product-detail-3 .content").toggleClass("open");
    $(this).toggleClass("open");
    $(this).find(".text").toggleText("Xem thêm", "Thu Gọn");
  });

  $(".oem-4 .view-more").click(function (e) {
    e.preventDefault();
    $(".oem-4 .content").toggleClass("open");
    $(this).toggleClass("open");
    $(this).find(".text").toggleText("Xem thêm", "Thu Gọn");
  });

  $(".btn-apply").click(function (e) {
    e.preventDefault();
    $(".apply-form").slideToggle();
  });

  $(".product-comment").append($(".commentpanel"));
});

try {
  var elm = document.querySelector(".about-nav-wrapper ul");
  var ms = new MenuSpy(elm, {
    threshold: 80,
  });
} catch (error) {}

$.fn.extend({
  toggleText: function (a, b) {
    return this.text(this.text() == b ? a : b);
  },
});

var productThumbnailSlider = new Swiper(
  ".product-thumbnail-slider .swiper-container",
  {
    direction: "vertical",
    slidesPerView: 5,
    spaceBetween: 10,
    observer: true,
    observeParents: true,
    navigation: {
      prevEl: ".product-thumbnail-slider  .swiper-prev",
      nextEl: ".product-thumbnail-slider  .swiper-next",
    },
    breakpoints: {
      768: {
        direction: "horizontal",
      },
      576: {
        slidesPerView: 3,
        direction: "horizontal",
      },
    },
  }
);

var productImagesSlider = new Swiper(
  ".product-image-slider .swiper-container",
  {
    spaceBetween: 10,
    observer: true,
    observeParents: true,
    thumbs: {
      swiper: productThumbnailSlider,
    },
    on: {
      slideChangeTransitionEnd: function () {
        ZoomImg();
      },
    },
  }
);

var yearDotSlider = new Swiper(".year-dot-slider .swiper-container", {
  slidesPerView: 10,
  observer: true,
  observeParents: true,
  breakpoints: {
    576: {
      slidesPerView: 5,
    },
  },
});

var yearContentSlider = new Swiper(".year-content-slider .swiper-container", {
  slidesPerView: 1.5,
  spaceBetween: 30,
  centeredSlides: true,
  slideToClickedSlide: true,
  observer: true,
  observeParents: true,
  thumbs: {
    swiper: yearDotSlider,
  },
  breakpoints: {
    576: {
      slidesPerView: 1,
    },
  },
});

function ZoomImg() {
  if ($(window).width() > 1024) {
    $(".swiper-slide-active .zoom-img").ezPlus({
      gallery: "gallery_01",
      cursor: "pointer",
      galleryActiveClass: "active",
      imageCrossfade: true,
      loadingIcon: "http://www.elevateweb.co.uk/spinner.gif",
      zoomWindowWidth: "500",
      zoomWindowHeight: "500",
      borderSize: "1",
      borderColour: "#ff4900",
    });
  }
}

const menuMobileMapping = new MappingListener({
  selector: ".main-menu",
  mobileWrapper: ".mobile-menu",
  mobileMethod: "appendTo",
  desktopWrapper: ".menu-wrapper",
  desktopMethod: "prependTo",
  breakpoint: 1025,
}).watch();

const catalogueMapping = new MappingListener({
  selector: ".catalogue-wrapper",
  mobileWrapper: ".mobile-menu",
  mobileMethod: "appendTo",
  desktopWrapper: ".right-stuff",
  desktopMethod: "prependTo",
  breakpoint: 1025,
}).watch();
